$(function() {

  //画像のリストをクリックした時の処理
  $('.index-btn').click(function() {
    $('.active').removeClass('active');
    var clickedIndex = $('.index-btn').index($(this));
    $('.slide').eq(clickedIndex).addClass('active');
  });

  $('.change-btn').click(function() {
    //activeクラスを今持っている要素を変数に入れる
    var $displaySlide = $('.active');
    //activeクラスを今持っている要素のindexを変数に入れる
    var slideIndex = $('.slide').index($('.active'));

    //今表示されているimg要素からactiveを除き、非表示にする
    $displaySlide.removeClass('active');
    //クリックした.change-btnがnext-btnだったら
    if ($(this).hasClass('next-btn')) {
      //最後のimg要素だったら
      if (slideIndex == $('.slide').length-1) {
        //先頭のimg要素にactiveをつけて表示する（nth-child(1)１番目の要素を指定）
        var $firstSlide = $('.slide:first');
        $firstSlide.addClass('active');
      } else {
      //最後意外のimg要素だったら
      //activeクラスを持っていたslideクラス要素の次のimg要素にactiveをつけて表示する
      $displaySlide.next().addClass('active');
      }

    } else {
      //クリックした.change-btnがprev-btnだったら、
      //最初のimg要素だったら
      if (slideIndex == 0) {
        //先頭のimg要素にactiveをつけて表示する（nth-child(1)１番目の要素を指定）
        var $lastSlide = $('.slide:last');
        $lastSlide.addClass('active');
      } else {
      //最初意外のimg要素だったら
      //activeクラスを持っていたslideクラス要素の1つ前のimg要素にactiveをつけて表示する
      $displaySlide.prev().addClass('active');
      }
    }
  });
});
